<template>
    <v-btn
        small
        block
        color="white"
        @click="onClick"
        class="w-100 p-4 border-radius-small"
      >
        <div class="d-flex justify-content-center w-100 d-flex align-center hint_text_color--text" >
        <img v-show="socialName==='google'" src="@/assets/images/page-img/google.svg" />
        <img v-show="socialName==='facebook'" src="@/assets/images/page-img/facebook.svg" />
        <span class="text-center w-100">{{ text }}</span>
      </div>
      </v-btn>
  </template>
  <script>
  export default {
    name: 'SocialActionButton',
    props: ['onClick', 'socialName', 'text']
  };
  </script>
